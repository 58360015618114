<template>
    <CModal
      title="Cargar Archivo"
      :color="color"
      size="lg"
      :show.sync="uploadModal"
      style="z-index: 100001"
    >   
        <CForm id="file_form" method="POST" enctype="multipart/form-data">
            <CRow>
              <CCol md="12">
                <CInput
                    type="file"
                    name="file"
                    id="file"
                    :label="label"
                    :lazy="false"
                />
              </CCol>
            </CRow>
        </CForm>
        <template #footer>
          <CButton @click="closeModal" color="secondary">Cancelar</CButton>
          <CButton @click="upload" :color="color">Cargar Archivo</CButton>
        </template>
    </CModal>
</template>

<script>
import students from '../../../services/students';
import ws from '../../../services/travels';
import store from '../../../store'

export default {
  name: 'TravelModal',
  components: {
    
  },
  props: {

  },
  data () {
    return {
      uploadModal: false,
      color: "info",
      travel_id: 0,
      travel_form_id: 0,
      response_id: 0,
      student_id: 0,
      question_key: 0,
      label: "Archivo:",
      item: false,
      field: false
    }
  },
  mounted: async function() {

  },
  methods: {
    show (title, item, field) {
      this.uploadModal = true;
      this.color = "info";
      
      this.label = field.label;

      this.travel_id = item.travel_id;
      this.travel_form_id = item.travel_form_id !== undefined ? item.travel_form_id : 0;
      this.student_id = item.id;
      this.response_id = item.response_id != undefined ? item.response_id : 0;
      this.question_key = field.key;

      this.item = item;
      this.field = field;
    },
    show2 (title, student_id, item, field) {
      this.uploadModal = true;
      this.color = "info";
      
      this.label = field.label;

      this.travel_id = item.travel_id;
      this.travel_form_id = item.travel_form_id !== undefined ? item.travel_form_id : 0;
      this.student_id = student_id;
      this.response_id = item.response_id != undefined ? item.response_id : 0;
      this.question_key = field.key;

      this.item = item;
      this.field = field;
    },
    async upload () {
      const form = document.getElementById("file_form");
      const formData = new FormData(form);

      formData.append("travel_id", this.travel_id);
      formData.append("student_id", this.student_id);
      formData.append("question_key", this.question_key);

      let response = false;
      if(this.travel_form_id > 0){
        formData.append("travel_form_id", this.travel_form_id);
        formData.append("response_id", this.response_id);

        response = await ws.uploadFormFile(formData);
      }
      else{
        response = await students.uploadFile(formData);
      }
    
      if(response.type == "success"){
        this.$emit("upload", this.item, this.field, response.data );
      }

      this.showToast(response.type, response.message);

      this.closeModal();
    },
    closeModal () {
      this.uploadModal = false;
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>
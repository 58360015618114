import axios from 'axios'

export default {
    list: () => axios.get('students/list'),
    showById: (id) => axios.get('students/id:'+id+'/show'),
    uploadFile: (data) => axios.post('students/uploading', data),
    delete: (id) => axios.post('students/delete', id),
    payments: (id) => axios.get('students/id:'+id+'/payments'),
    studentsByTravel: (id) => axios.get('travels/id:'+id+'/students'),
    studentsWithPayments: (id) => axios.get('travels/id:'+id+'/active-students-with-payments'),
    activeStudents: (id) => axios.get('travels/id:'+id+'/active-students'),
    activeDetailedStudents: (id) => axios.get('travels/id:'+id+'/active-detailed-students'),
    deactiveStudents: (id) => axios.get('travels/id:'+id+'/deactive-students'),
    waitingStudents: (id) => axios.get('travels/id:'+id+'/waiting-students'),
    activeStudent: (data) => axios.post('travels/id:'+data.id+'/active-student', data),
    deactiveStudent: (data) => axios.post('travels/id:'+data.id+'/deactive-student', data),
    waitStudent: (data) => axios.post('travels/id:'+data.id+'/waiting-student', data),
    updateObservations: (data) => axios.post('travels/id:'+data.id+'/update-observations', data),
    checkPayment: (student_id, payment_id) => axios.get('students/id:'+student_id+'/payment:'+payment_id),
    download: (id, type) => axios.post('travels/students/download', {id: id, type: type}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadFiltered: (id, student_ids) => axios.post('travels/students/filtered-download', {id: id, student_ids: student_ids}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    getStudentsExternal: (code) => axios.get('travels/code:'+code+'/students/external'),
}
import axios from 'axios'

export default {
    get: () => axios.get('payments'),
    list: () => axios.get('payments/list'),
    show: (code) => axios.get('payments/'+code+'/show'),
    showById: (id) => axios.get('payments/id:'+id+'/show'),
    store: (data) => axios.post('payments/store', data),
    update: (data) => axios.post('payments/update', data),
    delete: (id) => axios.post('payments/delete', id),
    deleteTravelPayment: (id) => axios.post('payments/delete-travel-payment', id),
    validate: (data) => axios.post('payments/validate', data),
    devalidate: (data) => axios.post('payments/devalidate', data),
    validation: (data) => axios.post('payments/validation', data),
    validations: () => axios.get('payments/validations'),
}